import React from "react";
import { Menu, Spin } from "antd";

import { Modal } from "../../../../../utils";

const TableMenu = ({ record, handleRemove, handleEdit, deleteProp, descProp, desc, handleActive, loadingActive }) => {
  return (
    <Menu>
      <Menu.Item
        key="0"
        disabled={loadingActive}
        onClick={() => {
          handleEdit(record);
        }}
      >
        Editar
      </Menu.Item>

      {handleActive && (
        <Menu.Item
          key="1"
          disabled={loadingActive}
          onClick={() => {
            handleActive(record);
          }}
        >
          Liberar {loadingActive && <Spin size="small" />}
        </Menu.Item>
      )}

      <Menu.Item
        key="2"
        danger
        disabled={loadingActive}
        onClick={() =>
          Modal.openDeleteModal({
            description: (
              <span>
                {`Deseja realmente deletar/inativar o ${desc}`}
                <strong> {record[descProp]}</strong>?
              </span>
            ),
            onOk: () => handleRemove(record[deleteProp]),
            okText: "Sim",
            cancelText: "Não",
          })
        }
      >
        Excluir
      </Menu.Item>
    </Menu>
  );
};

export default TableMenu;
