import { create } from "apisauce";
import { Storage, Toast } from "utils";
import configureStore from "store";
import { Creators } from "store/reducers/auth";

const api = create({
  baseURL: "/",
  // baseURL: "http://localhost:3333/",
  timeout: 240000,
});

function translateRequestErrorResponse(message) {
  if (message === "Access is denied") {
    return "Você não possui acesso suficiente para utilizar esse serviço.";
  }
  return message;
}

const generateRequestTransform = () => {
  return (request) => {
    const token = Storage.getToken();
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
  };
};

const generateResponseTransform = () => {
  return (response) => {
    try {
      if (!response.ok) {
        if (response.status === 401) {
          Toast.showRequestErrorMessage(
            response?.data?.error?.message ||
              response?.data?.error ||
              response?.data.message ||
              response?.data[0]?.message ||
              "É necessário estar autenticado para utilizar esse serviço."
          );
          const { dispatch } = configureStore().store;
          setTimeout(() => {
            dispatch(Creators.logout());
          }, 500);
        } else {
          if (response && response.data) {
            Toast.showRequestErrorMessage(
              translateRequestErrorResponse(
                response?.data?.error?.message ||
                  response?.data?.detail ||
                  response?.data?.title ||
                  response?.data.error ||
                  (response &&
                    response.data &&
                    typeof response.data === "string" &&
                    response.data) ||
                  "Erro interno no servidor."
              )
            );
          }
        }
      }
    } catch (e) {
      Toast.showRequestErrorMessage("Erro interno no servidor.");
    }
  };
};

api.addResponseTransform(generateResponseTransform());
api.addRequestTransform(generateRequestTransform());

export default api;
