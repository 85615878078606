import * as Excel from "exceljs";
import Moment from "moment";

export async function generateReportXLSX(data) {
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet("Relatório");

  const maxComarca = Math.max(...data.map((el) => el["nm_comarca"] && el["nm_comarca"].length));
  const maxParoquia = Math.max(...data.map((el) => el["nm_paroquia"] && el["nm_paroquia"].length));
  const maxEmail = Math.max(...data.map((el) => el["email"] && el["email"].length));
  const maxUsername = Math.max(...data.map((el) => el["username"] && el["username"].length));
  const maxFone = Math.max(...data.map((el) => el["nu_celular"] && el["nu_celular"].length));
  const maxCpf = Math.max(...data.map((el) => el["nu_cpf"] && el["nu_cpf"].length));
  const maxInscricao = Math.max(...data.map((el) => el["dt_inscicao"] && el["dt_inscicao"].length));

  worksheet.columns = [
    { header: "Nome", key: "username", width: maxUsername || 12 },
    { header: "Email", key: "email", width: maxEmail || 12 },
    { header: "Fone", key: "nu_celular", width: maxFone || 12 },
    { header: "CPF", key: "nu_cpf", width: maxCpf || 12 },
    { header: "Comarca", key: "nm_comarca", width: maxComarca || 12 },
    { header: "Paróquia", key: "nm_paroquia", width: maxParoquia || 12 },
    { header: "Data Inscrição", key: "dt_inscicao", width: maxInscricao || 12 },
    { header: "Cód. Promocional", key: "ds_codigo_promocional", width: 20 },
    { header: "Valor Pago", key: "valor", width: 20 },
    { header: "Progresso", key: "pr_concluido", width: 12 },
    {
      header: "Total de treinamentos assistidos",
      key: "totalAssistido",
      width: 35,
    },
  ];

  worksheet.getRow(1).font = { bold: true };

  data.forEach((e) => {
    worksheet.addRow({
      ...e,
      pr_concluido: `${e.pr_concluido}%`,
      valor: `${e.valor ?? 0}`,
      totalAssistido:
        e.treinamentos && e.treinamentos.length > 0
          ? e.treinamentos.filter((t) => t.treinamento_fg_concluido).length
          : 0,
    });
  });

  // posiciona todos elementos na esquerda
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((i) => {
    worksheet.getColumn(i).alignment = { horizontal: "left" };
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blobData = new Blob([buffer], { type: "application/xlsx" });
  const url = window.URL.createObjectURL(blobData);
  const tempLink = document.createElement("a");
  tempLink.href = url;
  tempLink.setAttribute(
    "download",
    `relatorioProgressoAluno-${Moment().format("DD/MM/YYYY")}.xlsx`
  );
  tempLink.click();
}
