import api from "./api";

const updateCourseRequest = (param, course) => {
  return api.put(`api/cursos/${course.id}`, course);
};

const findCoursePublicByIdRequest = (id) => {
  return api.get(`api/cursos-public/${id}`);
};

const findCourseByIdRequest = (id) => {
  return api.get(`api/cursos/${id}`);
};

const registerUserCourseRequest = (param, body) => {
  return api.post(`api/usuarioCurso`, body);
};

const createCourseRequest = (param, body) => {
  return api.post(`api/cursos/`, body);
};

const getCourseUserProgressRequest = (courseId) => {
  return api.get(`api/usuarioCurso/buscaUserCurso/${courseId}`);
};

const getCoursePdfRequest = (courseId) => {
  return api.get(`api/usuarioCurso/pdfCourse/${courseId}`);
};

const validateDiscountCode = (param, code) => {
  return api.get(`cursos/desconto/${code}`);
};

const getAllCourses = () => {
  return api.get("api/todosCursos");
};

const getAllCoursesBuy = () => {
  return api.get("api/todosCursos/compra");
};

const presignedUrl = (data) => {
  return api.post(`api/files/presignedUrl`, data);
};

const saveVideoToTraining = (trainingId, data) => {
  return api.post(`api/files/${trainingId}`, data);
};

const getAllCoursesReport = (parmas, filter) => {
  return api.get("api/cursos/relatorio/progresso", filter);
};

const getRunningConcludedCoursesUserRequest = (userId, filter) => {
  return api.get("api/usuarioCurso/cursoAndamConcl", filter);
};

const getAllRunningCoursesUserRequest = (userId, filter) => {
  return api.get("api/usuarioCurso/cursoAndamento", filter);
};

const getAllConcludedCoursesUserRequest = (userId, filter) => {
  return api.get("api/usuarioCurso/cursoConcluido", filter);
};

const findCourseVideoTrainingRequest = (trainingId) => {
  return api.get(`api/usuarioCurso/buscaVideoTreinamento/${trainingId}`);
};

const findQuestionRequest = (trainingId) => {
  return api.get(`api/usuarioCurso/buscaQuestaoAlternativa/${trainingId}`);
};

const createExerciseAnswersRequest = (param, answers) => {
  return api.post(`api/usuarioAlternativa/`, answers);
};

const findCoursesSearchRequest = (params, filter) => {
  return api.get(`api/cursoss/search/`, filter);
};

const getExerciseAnswersRequest = (params) => {
  return api.get(`api/usuarioAlternativa/${params}`);
};

const findCoursesRequest = (params, filter) => {
  return api.get("api/cursos", filter);
};

const deleteCourseRequest = (param, id) => {
  return api.delete(`api/cursos/${id}`);
};

const markTrainingCompletedRequest = (trainingId) => {
  return api.get(`api/usuarioCurso/treinamentoConcluido/${trainingId}`);
};

const buyCourseCompleteRequest = (courseId, promotionalCode) => {
  return api.post(`api/usuarioCurso`, {
    curso_id: courseId,
    codigoPromo: promotionalCode,
  });
};

const validatePromotionCodeRequest = (promotionalCode) => {
  return api.get(
    `api/codigoPromocional/validaCodPromocional/${promotionalCode}`
  );
};

const getChargeByUser = (idUser) => {
  return api.get(`api/cursos/boletos/${idUser}`);
}

const activeTopicCourseRequest = (data) => {
  return api.post(`api/usuarioCurso/topicoTreinamentoUserCurso`, data);
};

export {
  presignedUrl,
  getAllCourses,
  getChargeByUser,
  getAllCoursesBuy,
  findCoursesRequest,
  saveVideoToTraining,
  getCoursePdfRequest,
  findQuestionRequest,
  createCourseRequest,
  deleteCourseRequest,
  updateCourseRequest,
  getAllCoursesReport,
  validateDiscountCode,
  findCourseByIdRequest,
  activeTopicCourseRequest,
  findCoursesSearchRequest,
  buyCourseCompleteRequest,
  getExerciseAnswersRequest,
  registerUserCourseRequest,
  findCoursePublicByIdRequest,
  createExerciseAnswersRequest,
  getCourseUserProgressRequest,
  markTrainingCompletedRequest,
  validatePromotionCodeRequest,
  findCourseVideoTrainingRequest,
  getAllRunningCoursesUserRequest,
  getAllConcludedCoursesUserRequest,
  getRunningConcludedCoursesUserRequest,
};
